import { Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrEventFilesView',
  computed: {
    ...mapState('media', ['media'])
  }
})
export default class GtrEventFilesView extends GtrSuper {
  data () {
    return {
      loading: false,
      medias: [],
      mediaToDelete: null
    }
  }

  async mounted () {
    await this.fetchEventMedias()
  }

  @Watch('media', { immediate: true })
  onMediasChange (media: any) {
    this.$data.medias = media.Active
  }

  async onFileUploadChange (files: File[]) {
    this.$data.loading = true
    let i = 0
    let errorCount = 0
    while (i < files.length) {
      try {
        await this.$store.dispatch('media/uploadMedia', { event_uuid: this.$route.params.event_uuid, fileData: files[i], fetch: false })
      } catch (error) {
        errorCount++
      }
      i++
    }
    await this.$store.dispatch('media/getMedia', { event_uuid: this.$route.params.event_uuid })
    this.$data.loading = false
    if (files.length === 1) {
      if (errorCount === 0) {
        Container.get(Notification).success('File successfully uploaded.')
      } else {
        Container.get(Notification).success('Error on file upload.')
      }
    } else if (files.length > 1) {
      if (errorCount === 0) {
        Container.get(Notification).success('Files successfully uploaded.')
      } else {
        Container.get(Notification).success('Error on files upload.')
      }
    }
  }

  handleDeleteMedia (payload: any) {
    this.$data.mediaToDelete = payload
  }

  onCopyLinkSuccess () {
    Container.get(Notification).success('Link successfully copied.')
  }

  onCopyLinkError () {
    Container.get(Notification).error('There was an error copying the link. Please refresh and try again.')
  }

  async handleDeleteMediaAction (payload: any) {
    if (payload.confirm) {
      try {
        this.$data.loading = true
        await this.$store.dispatch('media/removeMedia', { event_uuid: this.$route.params.event_uuid, file_identifier: this.$data.mediaToDelete.file_identifier, fetch: false })
        await this.$store.dispatch('media/getMedia', { event_uuid: this.$route.params.event_uuid })
        Container.get(Notification).success('File successfully deleted.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
      }
    }
    this.$data.mediaToDelete = null
  }

  private async fetchEventMedias () {
    this.$data.loading = true
    const event_uuid = this.$route.params.event_uuid
    await this.$store.dispatch('media/getMedia', { event_uuid })
    this.$data.loading = false
  }

  private isFileAnImage (filename: string) {
    const imageExtensions: string[] = ['png', 'jpg', 'jpeg']
    const extension: string | undefined = filename.split('.').pop()
    if (extension) {
      if (imageExtensions.includes(extension)) {
        return true
      }
      return false
    }
    return false
  }
}
